import { Grid, Box, TextField } from "@mui/material";
import { useTranslation } from "next-i18next";

import { isValidEmail } from "@utils";
import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { CONTACT_US } from "@operations/leads/mutations";
import ConfirmationPopup from "@components/common/ConfirmationPopup";
import * as ga from "@lib/ga";
import ButtonWithArrow from "@components/common/ButtonWithArrow";

export default function ContactSection() {
  const { t } = useTranslation("common");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [department, setDepartment] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState({
    email: false,
    message: false,
  });

  const [confirmPopup, setConfirmPopup] = useState(false);

  const [contactUs] = useMutation(CONTACT_US, {
    onError: () => {},
    onCompleted: () => {
      setConfirmPopup(true);
      setEmail("");
      setName("");
      setCompany("");
      setDepartment("");
      setMessage("");
    },
  });

  const handleSubmit = () => {
    ga.event({ action: "plants for workspace - send message" });
    if (!email || !message) {
      setError({
        ...error,
        email: !email,
        message: !message,
      });
    } else {
      contactUs({
        variables: {
          email,
          subject: "Workspace Enquiry - "
            .concat(name)
            .concat(" - ")
            .concat(company)
            .concat(" - ")
            .concat(department),
          message,
        },
      });
    }
  };

  return (
    <>
      <Grid
        container
        id={"bundle-contact"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={2}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            value={name}
            onChange={(event) => {
              setName(event.target.value);
              setError({
                ...error,
                name: false,
              });
            }}
            placeholder={t("name")}
            error={error.name && Boolean(error.name)}
            helperText={error.name && t("email_error_message")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            value={email}
            onBlur={() => {
              if (!isValidEmail(email)) {
                setError({ ...error, email: true });
              }
            }}
            onChange={(event) => {
              setEmail(event.target.value);
              setError({
                ...error,
                email: false,
              });
            }}
            placeholder={t("work_email")}
            error={error.email && Boolean(error.email)}
            helperText={error.email && t("email_error_message")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            value={company}
            onChange={(event) => {
              setCompany(event.target.value);
              setError({
                ...error,
                company: false,
              });
            }}
            placeholder={t("company")}
            error={error.email && Boolean(error.email)}
            helperText={error.email && t("email_error_message")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            required
            value={department}
            onChange={(event) => {
              setDepartment(event.target.value);
              setError({
                ...error,
                department: false,
              });
            }}
            placeholder={t("department")}
            error={error.email && Boolean(error.email)}
            helperText={error.email && t("email_error_message")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            value={message}
            onChange={(event) => {
              setMessage(event.target.value);
              setError({
                ...error,
                message: false,
              });
            }}
            placeholder="Please mention your requirements so we can get back to you with a personalized solution"
            multiline
            rows={5}
            error={error.message && Boolean(error.message)}
            helperText={error.message && t("message_error")}
          />
        </Grid>
        <Grid item xs={12}>
          <Box>
            <ButtonWithArrow onClick={handleSubmit}>Submit</ButtonWithArrow>
          </Box>
        </Grid>
      </Grid>
      <ConfirmationPopup
        open={confirmPopup}
        handleClose={() => {
          setConfirmPopup(false);
        }}
        title={t("enquiry_received")}
        description={t("get_back_shortly")}
      />
    </>
  );
}

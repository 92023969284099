import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Button, Typography } from "@mui/material";
import Image from "next/image";
import PropTypes from "prop-types";
import { useTranslation } from "next-i18next";

const ConfirmationPopup = ({
  open = false,
  handleClose = () => null,
  handleButtonClick,
  title,
  description,
  icon,
  buttonProps,
  dialogProps,
  paperProps,
  buttonLabel,
}) => {
  const { t } = useTranslation("common");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="confirmation-dialog"
      aria-describedby="confirmation-dialog"
      PaperProps={{
        sx: {
          maxWidth: "569px",
          ...paperProps,
        },
      }}
    >
      <DialogContent {...dialogProps}>
        {icon ? (
          <Image
            src={icon}
            width="211"
            height="211"
            layout="fixed"
            alt={icon}
          />
        ) : null}
        <Typography variant="subtitle2">{title}</Typography>
        <Typography
          variant="h6"
          fontFamily="Inter"
          mt={1.5}
          color="text.tertiary"
        >
          {description}
        </Typography>

        <Button
          variant="outlined"
          onClick={() => {
            if (handleButtonClick) {
              handleButtonClick();
            } else {
              handleClose();
            }
          }}
          sx={{ mt: 5 }}
          {...buttonProps}
        >
          {buttonLabel || t("done", { ns: "common" })}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

ConfirmationPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonProps: PropTypes.object,
  dialogProps: PropTypes.object,
  paperProps: PropTypes.object,
  icon: PropTypes.string,
};

export default ConfirmationPopup;
